import { template as template_65bfc57f44fa49a6bff5ebb31bdf3aa8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_65bfc57f44fa49a6bff5ebb31bdf3aa8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
